import { useMutation } from '@tanstack/react-query'

import { Membership } from 'tokyu-hooks'

import { getClientSession } from 'lib/client/clientSession'
import { getAccessToken } from 'lib/client/user'
import { useParams } from 'next/navigation'

const cancelMembership = (locale?: string) => async () => {
  const clientSession = await getClientSession()
  const accessToken = await getAccessToken()

  return Membership.cancelMembership({
    baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
    clientSession,
    hotelCode: process.env.NEXT_PUBLIC_TRIPLA_HOTEL_CODE,
    accessToken,
    locale,
  })
}

const useCancelMembership = (
  onSuccess?: () => void,
  onError?: (error: any, variables: any, context: any) => void,
) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return useMutation({
    mutationFn: cancelMembership(locale),
    onSuccess: () => {
      onSuccess && onSuccess()
    },
    onError: onError,
  })
}

export default useCancelMembership
