import { Membership } from 'tokyu-hooks'

import { getClientSession } from 'lib/client/clientSession'
import { getAccessToken } from 'lib/client/user'

export const QUERY_MEMBERSHIP_DETAIL_KEY = 'QUERY_MEMBERSHIP_DETAIL_KEY'

export const fetchMembershipDetail = async ({
  queryKey,
}: {
  queryKey: any[]
}) => {
  const [_key, { locale }] = queryKey

  const clientSession = await getClientSession()
  const accessToken = await getAccessToken()
  if (!accessToken) return {}

  const res = await Membership.getMembershipDetail({
    baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
    clientSession,
    hotelCode: process.env.NEXT_PUBLIC_TRIPLA_HOTEL_CODE as string,
    accessToken,
    locale,
  })

  return res?.data?.data
}
