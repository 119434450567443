'use client'
import Link from 'next/link'
import classNames from 'classnames'
import type { ReactElement } from 'react'
import { useParams, usePathname } from 'next/navigation'

interface MenuItemProps {
  icon: ReactElement
  name: string
  href?: string
  path?: string
  onClick?: () => void
  onSelect?: () => void
}

const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  icon,
  name,
  href,
  path,
  onClick,
  onSelect,
}) => {
  const pathname = usePathname() || ''

  if (href) {
    return (
      <li>
        <Link
          href={href}
          className={classNames(
            'flex items-center space-x-3.5',
            path && pathname?.startsWith(path)
              ? 'text-primary lg:text-white'
              : 'text-gray-light lg:text-white lg:text-opacity-50',
          )}
          onClick={() => onSelect && onSelect()}
        >
          <div className="w-6 flex justify-center text-current">{icon}</div>
          <div className="text-current">{name}</div>
        </Link>
      </li>
    )
  }

  if (onClick) {
    return (
      <li
        className="cursor-pointer flex items-center space-x-3.5 text-gray-light lg:text-white lg:text-opacity-50"
        onClick={() => {
          onClick()
          onSelect && onSelect()
        }}
      >
        <div className="w-6 flex justify-center text-current">{icon}</div>
        <div className="text-current">{name}</div>
      </li>
    )
  }

  return null
}

export default MenuItem
