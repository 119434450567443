import React, { Fragment, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import Link from 'next/link'
import { useTranslation } from 'app/i18n/client'
import cookieCutter from 'cookie-cutter'

import useQueryMembershipDetail from 'app/hooks/useQueryMembershipDetail'
import useQueryUserPointDetail from 'app/hooks/useQueryUserPointDetail'
import useCancelMembership from 'app/hooks/useCancelMembership'

import { Button, Loading, Logo } from 'app/components/atoms'

import ChangeLanguagePopup from './ChangeLanguagePopup'
import AccountInfo from './AccountInfo'
import MobileMenu from './MobileMenu'

import CrossIcon from 'public/icons/cross-lg.svg'
import MenuIcon from 'public/icons/menu.svg'
import LogoutModal from '../LogoutModal'
import DeleteAccountModal from '../DeleteAccountModal'
import { useParams } from 'next/navigation'

interface HeaderProps {
  isMobileMenuOpen?: boolean
  closeMobileMenu?: () => void
}

const Header: React.FunctionComponent<HeaderProps> = ({
  isMobileMenuOpen = false,
  closeMobileMenu,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false)
  const [confirmLogout, setConfirmLogout] = useState(false)

  useEffect(() => {
    cookieCutter.set('NEXT_LOCALE', locale, {
      httpOnly: true,
      secure: true,
      sameSite: 'lax',
      path: '/',
    })
  }, [locale])

  const { mutate: cancelMembership, isLoading: isDeleting } =
    useCancelMembership(
      () => {
        setConfirmDeleteAccount(false)
        logout()
      },
      () => {
        toast.error(t('Something went wrong on deleting your account'))
      },
    )

  const {
    isLoading: isLoadingCurrentUser,
    isAuthenticated,
    currentUser,
    logout,
  } = useQueryMembershipDetail()

  const { data: pointDetail, isLoading, isError } = useQueryUserPointDetail()

  const onLogout = () => {
    logout()
    setConfirmLogout(false)
  }

  const formatUsername = (
    locale: 'en' | 'ja' | 'ko' | 'zh-Hans' | 'zh-Hant',
    currentUser: { first_name: string; last_name: string },
  ) =>
    ({
      en: `${currentUser?.first_name} ${currentUser?.last_name}`,
      ja: `${currentUser?.last_name} ${currentUser?.first_name}`,
      ko: `${currentUser?.last_name} ${currentUser?.first_name}`,
      'zh-Hans': `${currentUser?.last_name} ${currentUser?.first_name}`,
      'zh-Hant': `${currentUser?.last_name} ${currentUser?.first_name}`,
    })[locale]

  return (
    <>
      <div className="bg-white h-full flex flex-col lg:block lg:bg-white lg:h-auto">
        <div className="w-full lg:w-auto lg:max-w-7xl lg:mx-auto px-8 lg:px-0 flex items-center min-h-21 h-21 justify-between">
          <Logo />
          <div className="hidden lg:flex space-x-10 items-center justify-center">
            <ChangeLanguagePopup />
            {isLoadingCurrentUser ? (
              <div className="w-8 h-8 flex items-center justify-center">
                <Loading className="!text-white !opacity-60" size="sm" />
              </div>
            ) : isAuthenticated &&
              currentUser?.first_name &&
              currentUser?.last_name ? (
              <AccountInfo
                userName={
                  currentUser
                    ? formatUsername(
                        locale as 'en' | 'ja' | 'ko' | 'zh-Hans' | 'zh-Hant',
                        currentUser,
                      )
                    : ''
                }
                pointInfo={{
                  isLoading,
                  isError,
                  totalPoint: pointDetail?.totalPoint,
                }}
              />
            ) : (
              <div className="flex items-center">
                <Link href={`/${locale}/authentication/sign-up`} legacyBehavior>
                  <Button size="sm" className="!px-3 !uppercase">
                    {t('Sign Up')}
                  </Button>
                </Link>
                <div className="px-4">{t('or')}</div>
                <Link href={`/${locale}/authentication/sign-in`} legacyBehavior>
                  <Button
                    size="sm"
                    className="!px-3 !uppercase"
                    variant="white"
                  >
                    {t('Log In')}
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <Popover.Button className="w-full bg-white w-12 h-12 flex lg:hidden flex-col justify-center items-end space-y-2 focus:outline-none">
            {isMobileMenuOpen ? (
              <CrossIcon className="fill-current text-gray-dark" />
            ) : (
              <MenuIcon className="fill-current text-primary" />
            )}
          </Popover.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel
            as="nav"
            className="w-full h-full lg:hidden overflow-y-auto p-8 pb-0"
            aria-label="Global"
          >
            <MobileMenu
              isAuthenticated={isAuthenticated}
              close={closeMobileMenu}
              onLogout={() => {
                closeMobileMenu && closeMobileMenu()
                setConfirmLogout(true)
              }}
              onDeleteAccount={() => setConfirmDeleteAccount(true)}
            />
          </Popover.Panel>
        </Transition>
      </div>
      <LogoutModal
        open={confirmLogout}
        onClose={() => {
          setConfirmLogout(false)
        }}
        onConfirm={onLogout}
      />
      <DeleteAccountModal
        open={confirmDeleteAccount}
        onClose={() => setConfirmDeleteAccount(false)}
        onConfirm={() => cancelMembership(null)}
        isLoading={isDeleting}
      />
    </>
  )
}

export default Header
