'use client'
import { useTranslation } from 'app/i18n/client'
import { Button, Modal, ModalHeader } from 'app/components/atoms'

interface LogoutModalProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
}

const LogoutModal: React.FunctionComponent<LogoutModalProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <div className="inline-block bg-white shadow-xl transform transition-all w-82.5">
        <div className="py-4 px-6">
          <ModalHeader label={t('Come back soon!')} />
        </div>
        <div className="pb-6 px-6 border-t border-gray-150 text-left">
          <p className="py-14">{t('Are you sure you want to log out?')}</p>
          <div className="flex space-x-4">
            <Button
              size="sm"
              className="w-full uppercase !bg-red-400"
              onClick={() => onConfirm()}
            >
              {t('Log out')}
            </Button>
            <Button
              size="sm"
              variant="white"
              className="w-full uppercase"
              onClick={onClose}
            >
              {t('Close Modal')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LogoutModal
