import { signIn } from 'next-auth/react'
import { Button } from 'app/components/atoms'
import { useTranslation } from 'app/i18n/client'

const BasicAuthRequired: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="flex h-screen">
      <div className="m-auto text-center">
        <h1 className="text-2xl">{t('Please signin to access this page')}</h1>
        <Button onClick={() => signIn()} className="uppercase mt-4">
          {t('Sign In')}
        </Button>
      </div>
    </div>
  )
}

export default BasicAuthRequired
