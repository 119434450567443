import { useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { deleteUser, getUser, updateUser } from 'lib/client/user'
import { isEmpty } from 'lodash'
import { useParams, useRouter } from 'next/navigation'
import {
  QUERY_MEMBERSHIP_DETAIL_KEY,
  fetchMembershipDetail,
} from './fetchMembershipDetail'

const useQueryMembershipDetail = () => {
  const router = useRouter()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const queryClient = useQueryClient()

  const setCurrentUser = (user: any) => {
    queryClient.setQueryData([QUERY_MEMBERSHIP_DETAIL_KEY, { locale }], user)
  }

  const {
    data: profile,
    refetch,
    isError,
    error,
    isLoading,
  } = useQuery({
    queryKey: [QUERY_MEMBERSHIP_DETAIL_KEY, { locale }],
    queryFn: fetchMembershipDetail,
    retry: 1,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    if (isError && (error as any)?.response?.status === 401) logout()
  }, [isError, error])

  useEffect(() => {
    if (profile) {
      updateUser({
        ...getUser(),
        ...profile,
      })
    }
  }, [profile])

  const currentUser =
    typeof window !== 'undefined'
      ? {
          ...getUser(),
          ...profile,
        }
      : undefined

  const logout: (onComplete?: () => void) => void = (onComplete) => {
    setCurrentUser(null)
    deleteUser()
    onComplete ? onComplete() : router.push(`/${locale}`)
  }

  return {
    isAuthenticated: !!currentUser && !isEmpty(currentUser),
    currentUser,
    isLoading,
    isError,
    error,
    logout,
    refetch,
  }
}

export default useQueryMembershipDetail
