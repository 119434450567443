'use client'
import { useTranslation } from 'app/i18n/client'
import { Button, Modal, ModalHeader } from 'app/components/atoms'
import { Trans } from 'react-i18next'

interface DeleteAccountModalProps {
  open: boolean
  isLoading: boolean
  onConfirm: () => void
  onClose: () => void
}

const DeleteAccount: React.FunctionComponent<DeleteAccountModalProps> = ({
  open,
  onConfirm,
  onClose,
  isLoading,
}) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <div className="inline-block bg-white shadow-xl transform transition-all w-96">
        <div className="px-6 py-4">
          <ModalHeader label={t('Are you sure to delete your account?')} />
        </div>
        <div className="px-6 pb-6 text-left border-t border-gray-150">
          <p className="py-3.5 text-base leading-7">
            <Trans i18nKey="Confirm Delete Account Message">
              <div>
                Your personal information will be deleted from the system and
                all membership benefits will be invalidated. This operation is
                irreversible.
              </div>
              <div>
                However, the bookings that you have already made will not be
                deleted. If you want to confirm, edit or cancel your bookings
                after deleting your account, please follow the procedure from
                the booking confirmation emails.
              </div>
            </Trans>
          </p>
          <div className="flex space-x-2">
            <div className="flex-none">
              <Button
                size="sm"
                variant="white"
                className="!px-7 w-full uppercase !flex-none"
                onClick={onClose}
              >
                {t('Close')}
              </Button>
            </div>
            <div className="flex-1">
              <Button
                size="sm"
                className="w-full uppercase !bg-red-400 !flex-1"
                loading={isLoading}
                onClick={onConfirm}
              >
                {t('Yes, delete my account')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteAccount
