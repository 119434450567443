import { useQuery } from '@tanstack/react-query'

import { useParams } from 'next/navigation'
import useQueryMembershipDetail from '../useQueryMembershipDetail'
import {
  QUERY_USER_POINT_DETAIL,
  fetchUserPointDetail,
} from './fetchUserPointDetail'

const useQueryUserPointDetail = () => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const { isAuthenticated } = useQueryMembershipDetail()

  return useQuery({
    queryKey: [QUERY_USER_POINT_DETAIL, { locale }],
    queryFn: fetchUserPointDetail(),
    enabled: !!isAuthenticated,
  })
}

export default useQueryUserPointDetail
