import dayjs from 'lib/dayjs'

import { getClientSession, deleteClientSession } from './clientSession'

import { Membership } from 'tokyu-hooks'

const USER_KEY = 'tripla_user'

const getRefreshToken = () => getUser()?.refresh_token?.token

const isRefreshTokenExpired = () => {
  const user = getUser()
  return (
    user?.refresh_token &&
    dayjs(user?.refresh_token?.expires_at).isBefore(dayjs(new Date()))
  )
}

const isAccessTokenExpired = () => {
  const user = getUser()

  return user && dayjs(user?.expired_at).isBefore(dayjs(new Date()))
}

const refreshNewToken = async (refreshToken: string, clientSession: string) => {
  const res = await Membership.refreshToken({
    baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
    hotelCode: process.env.NEXT_PUBLIC_TRIPLA_HOTEL_CODE,
    clientSession,
    refreshToken,
  })

  return res?.data?.data
}

export const setUser = (user: any) =>
  localStorage.setItem(
    USER_KEY,
    JSON.stringify({
      ...user,
      expired_at: dayjs(new Date()).add(23, 'hour').toString(),
    }),
  )

export const deleteUser = () => {
  deleteClientSession()
  localStorage.removeItem(USER_KEY)
}

export const getUser = () => {
  const value = localStorage.getItem(USER_KEY)

  return value && JSON.parse(value)
}

export const getAccessToken = async () => {
  const user = getUser()

  if (!isAccessTokenExpired()) {
    return user?.access_token
  }

  if (!isRefreshTokenExpired()) {
    const refreshToken = getRefreshToken()
    if (!refreshToken) {
      deleteUser()
      return
    }
    const clientSession = await getClientSession()

    const newUser = await refreshNewToken(refreshToken, clientSession)

    // NOTE: fetch refreshToken failure
    if (!newUser) {
      deleteUser()
      return
    }

    setUser({
      ...newUser,
      refresh_token: user.refresh_token,
    })

    return newUser?.access_token
  }

  return undefined
}

export const updateUser = async (user: any) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user))
}
