import { IGetPointDetailResponse } from 'tokyu-hooks/lib/response/membership'
import { PointDetailModel } from './types'

type PointDetailData = IGetPointDetailResponse['data']

export const toPointDetailModel = (
  data: PointDetailData,
): PointDetailModel => ({
  minimumUsageUnit: data.minimum_usage_unit,
  usageUnit: data.usage_unit,
  pointUsingEnabled: data.point_using_enabled,
  totalPoint: data.total_point,
  totalValue: data.total_value,
})
