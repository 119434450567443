'use client'
import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'

import UserIcon from 'public/icons/user.svg'
import EmailIcon from 'public/icons/email.svg'
import LockIcon from 'public/icons/lock.svg'
import PointIcon from 'public/icons/point.svg'
import UsersIcon from 'public/icons/users.svg'
import TrashIcon from 'public/icons/trash.svg'
import AlertCircleIcon from 'public/icons/alert-circle.svg'
import HelpCircleIcon from 'public/icons/help-circle-lg.svg'
import ArrowDownIcon from 'public/icons/arrow-down.svg'
import ArrowUpIcon from 'public/icons/arrow-up.svg'

import { useTranslation } from 'app/i18n/client'
import MenuItem from 'app/components/layouts/MenuItem'
import { useParams } from 'next/navigation'

const AccountMenu: React.FunctionComponent<{
  close?: () => void
  onDeleteAccount?: () => void
}> = ({ close, onDeleteAccount }) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const personalMenuItems = [
    {
      icon: <UserIcon className="stroke-current" />,
      name: t('Edit Profile'),
      href: `/${locale}/my-page/profile`,
      path: `/${locale}/my-page/profile`,
    },
    {
      icon: <PointIcon className="stroke-current" />,
      name: t('Points Information'),
      href: `/${locale}/my-page/point-information`,
      path: `/${locale}/my-page/point-information`,
    },
    {
      icon: <EmailIcon className="fill-current" />,
      name: t('Change Mail Address'),
      href: `/${locale}/my-page/change-email`,
      path: `/${locale}/my-page/change-email`,
    },
    {
      icon: <LockIcon className="fill-current" />,
      name: t('Change Password'),
      href: `/${locale}/my-page/change-password`,
      path: `/${locale}/my-page/change-password`,
    },
    //{
    //icon: <CreditCardIcon className="stroke-current" />,
    //name: t('Payment'),
    //href: '/my-page/payment',
    //paths: ['/my-page/payment'],
    //},
    {
      icon: <TrashIcon className="stroke-current" />,
      name: t('Delete Account'),
      onClick: () => onDeleteAccount && onDeleteAccount(),
    },
  ]

  const settingsMenuItems = [
    {
      icon: <UsersIcon className="stroke-current" />,
      name: t('Membership Agreement'),
      href: t('MEMBERSHIP_AGREEMENT'),
    },
    {
      icon: <HelpCircleIcon className="stroke-current" />,
      name: t('Privacy Policy'),
      href: t('PRIVACY_POLICY_BY_THE_HOTEL'),
    },
    {
      icon: <AlertCircleIcon className="stroke-current" />,
      name: t('Clause'),
      href: t('CLAUSE'),
    },
  ]

  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-center justify-between w-full">
            <div className="font-lora text-2xl">{t('My Account')}</div>
            {open ? (
              <ArrowUpIcon className="ml-3 fill-current text-gray-dark" />
            ) : (
              <ArrowDownIcon className="ml-3 fill-current text-gray-dark" />
            )}
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Disclosure.Panel>
              <ul className="mt-6 space-y-4">
                {personalMenuItems.map((item) => (
                  <MenuItem key={item.name} {...item} onSelect={close} />
                ))}
              </ul>
              <h2 className="mt-10 font-lora">{t('Settings')}</h2>
              <ul className="mt-6 space-y-4">
                {settingsMenuItems.map((item) => (
                  <MenuItem key={item.name} {...item} onSelect={close} />
                ))}
              </ul>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default AccountMenu
