import type { ReactNode } from 'react'

import Head from 'next/head'
import BasicAuthRequired from './BasicAuthRequired'
import { useSession } from 'next-auth/react'
import classNames from 'classnames'
import { Popover } from '@headlessui/react'
import dayjs from 'lib/dayjs'
import { useParams } from 'next/navigation'

interface LayoutProps {
  skipBasicAuth?: boolean
  backgroundColor?: string
  renderHeader?: (
    isMobileMenuOpen?: boolean,
    closeMobileMenu?: () => void,
  ) => ReactNode
  renderMain?: (isMobileMenuOpen?: boolean) => ReactNode
  renderFooter?: (isMobileMenuOpen?: boolean) => ReactNode
  children?: ReactNode
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  skipBasicAuth = false,
  backgroundColor = 'bg-gray-bg',
  children,
  renderHeader,
  renderMain,
  renderFooter,
}) => {
  const { status } = useSession()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  dayjs.locale(
    ['zh-Hans', 'zh-Hant'].includes(locale as string) ? 'zh-cn' : locale,
  )

  if (
    !skipBasicAuth &&
    process.env.NEXT_PUBLIC_BASIC_AUTH_REQUIRED === 'true'
  ) {
    if (status === 'loading') {
      return null
    }

    if (status === 'unauthenticated') {
      return <BasicAuthRequired />
    }
  }

  return (
    <Popover
      as="div"
      className={({ open }) =>
        classNames('min-h-full', backgroundColor, {
          'fixed inset-0 z-40': open,
        })
      }
    >
      {({ open: isMobileMenuOpen, close: closeMobileMenu }) => (
        <>
          <Head>
            <link rel="icon" href="/favicon.ico" />
            <link
              rel="preload"
              href="/fonts/Avenir-Next.ttf"
              as="font"
              crossOrigin=""
            />
          </Head>
          {renderHeader && (
            <header className="sticky h-full lg:block top-0 z-[2]">
              {renderHeader(isMobileMenuOpen, closeMobileMenu)}
            </header>
          )}
          {renderMain ? (
            renderMain(isMobileMenuOpen)
          ) : (
            <main>
              <div className="max-w-7xl mx-auto lg:min-h-screen relative">
                {children}
              </div>
            </main>
          )}
          {renderFooter && <footer>{renderFooter(isMobileMenuOpen)}</footer>}
        </>
      )}
    </Popover>
  )
}

export default Layout
