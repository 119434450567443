'use client'
import React from 'react'

import Link from 'next/link'

import classNames from 'classnames'

import { Button } from 'app/components/atoms'

import ChangeLanguageMenu from './ChangeLanguageMenu'
import AccountMenu from './AccountMenu'
import { useTranslation } from 'app/i18n/client'
import { useParams, usePathname } from 'next/navigation'

interface MobileMenuProps {
  isAuthenticated: boolean
  close?: () => void
  onLogout: () => void
  onDeleteAccount: () => void
}

const MobileMenu: React.FunctionComponent<MobileMenuProps> = ({
  isAuthenticated,
  close,
  onLogout,
  onDeleteAccount,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const pathName = usePathname() || ''

  const isReservationUrl = pathName.startsWith(
    `/${locale}/my-page/reservations`,
  )

  const isHomeUrl = pathName === `/${locale}`

  return (
    <>
      <div className="flex flex-col border-t border-gray-100 divide-gray-100 divide-y h-full">
        <Link
          href={`/${locale}`}
          className={classNames(
            'py-5 font-lora text-2xl',
            isHomeUrl && 'text-primary',
          )}
          onClick={() => {
            close && close()
          }}
        >
          {t('Homepage')}
        </Link>
        {isAuthenticated ? (
          <>
            <Link
              href={`/${locale}/my-page/reservations`}
              className={classNames(
                'py-5 font-lora text-2xl',
                isReservationUrl && 'text-primary',
              )}
              onClick={() => {
                close && close()
              }}
            >
              {t('Bookings')}
            </Link>
            <div className="py-5 grow">
              <AccountMenu close={close} onDeleteAccount={onDeleteAccount} />
            </div>
          </>
        ) : (
          <div className="py-25 flex items-start justify-center grow">
            <div className="flex items-center">
              <Link href={`/${locale}/authentication/sign-up`} legacyBehavior>
                <Button className="!px-4 !uppercase !font-bold">
                  {t('Sign Up')}
                </Button>
              </Link>
              <div className="px-4 text-gray-light">{t('or')}</div>
              <Link href={`/${locale}/authentication/sign-in`} legacyBehavior>
                <Button variant="white" className="!px-4 !uppercase !font-bold">
                  {t('Log In')}
                </Button>
              </Link>
            </div>
          </div>
        )}
        <ChangeLanguageMenu onClose={close} />
        {isAuthenticated ? (
          <Button
            className="!bg-transparent !w-full !uppercase !py-8 !text-gray-dark"
            onClick={onLogout}
          >
            {t('Log out')}
          </Button>
        ) : (
          <div className="py-14" />
        )}
      </div>
    </>
  )
}

export default MobileMenu
