import { Membership } from 'tokyu-hooks'
import { getClientSession } from 'lib/client/clientSession'
import { getAccessToken } from 'lib/client/user'
import { toPointDetailModel } from 'core/profile'

export const QUERY_USER_POINT_DETAIL = 'QUERY_USER_POINT_DETAIL'

export const fetchUserPointDetail =
  (clientSession?: string) =>
  async ({ queryKey }: { queryKey: any[] }) => {
    const [_key, { locale }] = queryKey

    const accessToken = await getAccessToken()
    if (!accessToken) return null

    const res = await Membership.getPointDetail({
      baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
      clientSession: clientSession || (await getClientSession()),
      hotelCode: process.env.NEXT_PUBLIC_TRIPLA_HOTEL_CODE as string,
      accessToken,
      locale,
    })

    return res?.data?.data ? toPointDetailModel(res?.data?.data) : null
  }
